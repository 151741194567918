<template>
    <b-row class="match-height">
      <b-col lg="12">
        <b-card title="Purchase Order Edit">
          <b-row>
            <b-col cols="12">
              <b-button
                type="button"
                variant="primary"
                class="mr-1"
                @click="previous()"
              >
                <feather-icon
                  icon="ArrowLeftIcon"
                  size="16"
                  class="align-middle"
                />
                Back
              </b-button>
              <b-button
                v-if="purchaseOrderIsConfirmed === true && type == 'Lokal'"
                type="button"
                variant="primary"
                class="mr-1 float-rigth"
                @click="print()"
              >
                <feather-icon icon="PrinterIcon" size="16" class="align-middle" />
                Print
              </b-button>
              <div class="devider" />
            </b-col>
            <b-col cols="12">
        <b-form @keyup.enter.prevent="" @submit.prevent="submitEditPO">
              <b-form-group
                label="Number "
                label-for="number"
                label-cols-md="12"
              >
                <b-form-input
                  id="purchase_order_number"
                  v-model="number"
                  placeholder="Supplier"
                  required="required"
                  readonly
                />
              </b-form-group>
              <b-form-group
                label="Supplier "
                label-for="supplier"
                label-cols-md="12"
              >
                <v-select-new v-model="chosenContact" :options="contacts" />
              </b-form-group>
              <b-form-group
                label="Shipment By "
                label-for="purchase_order_shipment_by"
                label-cols-md="12"
              >
                <b-form-input
                  id="purchase_order_shipment_by"
                  v-model="shipmentBy"
                  placeholder="Shipment By"
                  required="required"
                />
              </b-form-group>
              <b-form-group
                label="Delivery Address "
                label-for="purchase_order_delivery_address"
                label-cols-md="12"
              >
                <b-form-input
                  id="purchase_order_delivery_address"
                  v-model="deliveryAddress"
                  placeholder="Delivery Address"
                  required="required"
                />
              </b-form-group>
              <b-form-group
                label="Payment Type "
                label-for="payment_type"
                label-cols-md="12"
              >
                <v-select-new
                  v-model="chosenPaymentType"
                  :options="paymentTypes"
                />
              </b-form-group>
              <b-form-group
                label="Delivery Schedule "
                label-for="purchase_order_delivery_schedule"
                label-cols-md="12"
                readonly
              >
                <b-form-datepicker v-model="deliverySchedule"
                  required="required"/>
              </b-form-group>
              <b-form-group
                label="Is With PPN "
                label-for="purchase_order_is_with_tax"
                label-cols-md="12"
                readonly
              >
                <v-select-new v-model="chosenPpn" :options="ppnOptions" />
              </b-form-group>
              <b-form-group
                label="Billing Address "
                label-for="billing_address"
                label-cols-md="12"
              >
                <v-select-new v-model="chosenBranch" :options="branches" />
              </b-form-group>
              <b-form-group
                label="Term Of Payment "
                label-for="term_of_payment"
                label-cols-md="12"
              >
                <b-form-input
                  id="term_of_payment"
                  v-model="termOfPayment"
                  placeholder="Term Of Payment"
                  required="required"
                />
              </b-form-group>
              <b-form-group
                label="Transport Fee"
                label-for="purchase_order_transport_fee"
                label-cols-md="12"
              >
                <b-form-input
                  id="purchase_order_transport_fee"
                  v-model="purchaseOrderTransportFee"
                  placeholder="Transport Fee"
                />
              </b-form-group>
              <b-form-group
                label="Discount Percentage (%)"
                label-for="purchase_order_discount_percentage"
                label-cols-md="12"
              >
                <b-form-input
                  id="purchase_order_discount_percentage"
                  v-model="purchaseOrderDiscountPercentage"
                  placeholder="Discount Percentage"
                />
              </b-form-group>
              <b-form-group
                label="Link"
                label-for="purchase_order_link"
                label-cols-md="12"
              >
                <b-form-input
                  id="purchase_order_link"
                  v-model="purchaseOrderLink"
                  placeholder="Link"
                />
              </b-form-group>
              <b-form-group>
                <label for="no_invoice">Items :</label>
              </b-form-group>
              <b-form-group>
                <br />
                <div v-if="purchaseOrderByPurchasingIsConfirmed === false">
                  <b-row>
                    <b-col cols="6" class="text-center font-weight-bold">
                      Item name
                    </b-col>
                    <b-col cols="2" class="text-center font-weight-bold">
                      Unit name
                    </b-col>
                    <b-col cols="2" class="text-center font-weight-bold">
                      Chosen Price
                    </b-col>
                    <b-col cols="2" class="text-center font-weight-bold">
                      Requested QTY
                    </b-col>
                  </b-row>
                  <p />
                  <b-row
                    v-for="item in items"
                    :key="item.purchase_order_detail_item_id"
                  >
                    <br />
                    <b-col cols="6" class="text-center">
                      <b-list-group>
                        <b-list-group-item href="#">
                          {{ item.item.item_name }} ({{ item.item.item_code }})
                        </b-list-group-item>
                      </b-list-group>
                    </b-col>

                    <b-col cols="2" class="text-center">
                      <b-list-group>
                        <b-list-group-item href="#">
                          {{
                            item.item.unit
                              ? capitalizeWords(item.item.unit.unit_name)
                              : "-"
                          }}
                        </b-list-group-item>
                      </b-list-group>
                    </b-col>
                    <b-col cols="2" class="text-center">

                      <b-form-input
                        v-model="item.purchase_order_detail_item_price"
                        class="text-center"
                      />
                    </b-col>
                    <b-col cols="2" class="text-center">
                      <b-form-input
                        v-model="item.purchase_order_detail_item_qty"
                        class="text-center"
                        readonly
                      />
                    </b-col>
                    <b-col cols="12">
                      <br />
                    </b-col>
                  </b-row>
                </div>
                <div
                  v-if="
                    purchaseOrderByPurchasingIsConfirmed === true &&
                      purchaseOrderByWarehouseIsConfirmed == false
                  "
                >
                  <b-row>
                    <b-col cols="4" class="text-center font-weight-bold">
                      Item name
                    </b-col>
                    <b-col cols="2" class="text-center font-weight-bold">
                      Unit name
                    </b-col>
                    <b-col cols="2" class="text-center font-weight-bold">
                      Chosen Price
                    </b-col>
                    <b-col cols="2" class="text-center font-weight-bold">
                      Sent QTY
                    </b-col>
                    <b-col cols="2" class="text-center font-weight-bold">
                      Received
                    </b-col>
                  </b-row>
                  <p />
                  <b-row
                    v-for="item in items"
                    :key="item.purchase_order_detail_item_id"
                  >
                    <br />
                    <b-col cols="4" class="text-center">
                      <b-list-group>
                        <b-list-group-item href="#">
                          {{ item.item.item_name }} ({{ item.item.item_code }})
                        </b-list-group-item>
                      </b-list-group>
                    </b-col>
                    <b-col cols="2" class="text-center"
                      ><b-list-group-item href="#">
                        {{
                          item.item.unit
                            ? capitalizeWords(item.item.unit.unit_name)
                            : "-"
                        }}
                      </b-list-group-item>
                    </b-col>
                    <b-col cols="2" class="text-center form-control">
                      {{
                        item.item_reference != null
                          ? item.item_reference.currency.currency_symbol
                          : item.item.currency.currency_symbol
                      }}
                      {{ numberFormat(item.purchase_order_detail_item_price) }}
                    </b-col>
                    <b-col cols="2" class="text-center">
                      <b-form-input
                        v-model="item.purchase_order_detail_item_qty"
                        class="text-center"
                        readonly
                      />
                    </b-col>
                    <b-col cols="12">
                      <br />
                    </b-col>
                  </b-row>
                </div>

                <div
                  v-if="
                    purchaseOrderByPurchasingIsConfirmed === true &&
                      purchaseOrderByWarehouseIsConfirmed == true
                  "
                >
                  <b-row>
                    <b-col cols="6" class="text-center font-weight-bold">
                      Item name
                    </b-col>
                    <b-col cols="2" class="text-center font-weight-bold">
                      Unit Name
                    </b-col>
                    <b-col cols="2" class="text-center font-weight-bold">
                      Chosen Price
                    </b-col>
                    <b-col cols="2" class="text-center font-weight-bold">
                      Sent QTY
                    </b-col>
                  </b-row>
                  <p />
                  <b-row
                    v-for="item in items"
                    :key="item.purchase_order_detail_item_id"
                  >
                    <br />
                    <b-col cols="6" class="text-center">
                      <b-list-group>
                        <b-list-group-item href="#">
                          {{ item.item.item_name }} ({{ item.item.item_code }})
                        </b-list-group-item>
                      </b-list-group>
                    </b-col>
                    <b-col cols="2" class="text-center">
                      <b-list-group-item href="#">
                        {{
                          item.item.unit
                            ? capitalizeWords(item.item.unit.unit_name)
                            : "-"
                        }}
                      </b-list-group-item>
                    </b-col>
                    <b-col cols="2" class="text-center form-control">
                      {{
                        item.item_reference != null
                          ? item.item_reference.currency.currency_symbol
                          : item.item.currency.currency_symbol
                      }}
                      {{ numberFormat(item.purchase_order_detail_item_price) }}
                    </b-col>
                    <b-col cols="2" class="text-center">
                      <b-form-input
                        v-model="item.purchase_order_detail_item_qty"
                        class="text-center"
                        readonly
                      />
                    </b-col>
                    <b-col cols="12">
                      <br />
                    </b-col>
                  </b-row>
                </div>
              </b-form-group>
              <b-form-group>
                <label for="no_invoice">Status :</label>
                <vue-horizontal-timeline :items="statusItems" />
              </b-form-group>
            <b-col offset-md="12">
              <b-button type="submit" variant="primary" class="mr-1"
                :disabled="submitButtonDisabled">
                Submit
              </b-button>
            </b-col>
              </b-form>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
    </b-row>
  </template>
  <script>
  import {
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BButton,
    BCard,
    BListGroup,
    BListGroupItem,
    BFormDatepicker,
  } from "bootstrap-vue"
  import ToastificationContent from "@core/components/toastification/ToastificationContent.vue"
  import axios from "axios"
  import moment from "moment"
  import Ripple from "vue-ripple-directive"
    import Select2 from 'v-select2-component'
  import { VueHorizontalTimeline } from "vue-horizontal-timeline"
  import { ref } from "@vue/composition-api"

  const VersionCheck = ""
const file = ""
const items = []
const prs = []
const chosenPR = ref()
const chosens = []
const shipmentTo = ""
const itemId = ""
const purchaseOrderRefNo = ""
const purchaseOrderProject = ""
const purchaseOrderId = ""
const purchaseOrderCostCenter = ""
const purchaseOrderLink = ""
const purchaseOrderQtyRequested = 0
const purchaseOrderTransportFee = 0
const purchaseOrderStatus = ""
const purchaseOrderNotes = ""
const purchaseOrderDiscountPercentage = ""
const deliverySchedule = ""
const shipmentBy = ""
const modalReferenceShow = false
const itemReference = ""
const itemLink = ""
const itemPrice = ""
const itemDescription = ""
const itemSupplier = ""
const contacts = []
const branches = []
const references = []
const currencies = []
const itemsUnfiltered = []
const types = ["Head Office", "Cabang"]
const chosenBranch = ""
const chosenContact = ""
const termOfPayment = ""
const billingAddress = ""
const deliveryAddress = ""
const itemCurrency = ""
const paymentTypes = ["Credit", "Cash", "COD"]
const ppnOptions = ["Yes", "No"]
const chosenPpn = ""
const chosenPaymentType = ""
const modalReferenceHistoryShow = false
const submitButtonDisabled = false
  export default {
    components: {
        Select2,
      VueHorizontalTimeline,
      BListGroup,
      BListGroupItem,
      BCard,
      BRow,
      BCol,
      BFormGroup,
      BFormInput,
      BForm,
      BButton,
    BFormDatepicker,
    },
    directives: {
      Ripple,
    },
    data() {
      return {
        purchaseOrderTransportFee,
        prs,
        purchaseOrderId,
      chosenPpn,
      ppnOptions,
      itemsUnfiltered,
      submitButtonDisabled,
      shipmentBy,
      purchaseOrderDiscountPercentage,
      types,
      purchaseOrderLink,
      itemSupplier,
      paymentTypes,
      chosenPaymentType,
      itemCurrency,
      currencies,
      deliveryAddress,
      chosenBranch,
      branches,
      termOfPayment,
      billingAddress,
      modalReferenceHistoryShow,
      chosenContact,
      contacts,
      itemId,
      deliverySchedule,
      file,
      modalReferenceShow,
      itemReference,
      itemLink,
      itemPrice,
      itemDescription,
      references,
      VersionCheck,
      items,
      chosenPR,
      chosens,
      shipmentTo,
      purchaseOrderRefNo,
      purchaseOrderProject,
      purchaseOrderCostCenter,
      purchaseOrderQtyRequested,
      purchaseOrderStatus,
      purchaseOrderNotes,
      }
    },
    mounted() {
      this.getDetail()
        this.getContacts()
        this.getBranches()
        this.getCurrencies()
    },
    methods: {

    getBranches() {
      const userToken = this.$cookies.get("userToken")
const headers = {
  "Content-Type": "application/json",
  Authorization: `Bearer ${userToken}`,
}
      axios
        .get(
          `${process.env.VUE_APP_API_URL}${process.env.VUE_APP_API_GET_BRANCHES}`,
          { headers },
        )
        .then(response => {
          if (response.data.success === true) {
            const itemsJoin = []
            const userData = this.$cookies.get('userData')
            const userBranch = userData.branch
            response.data.data.map(elem => {
              if (userBranch !== null && typeof userBranch !== 'undefined') {
                if (elem.branch_code === userBranch.branch_code) {
                  itemsJoin.push({
                    label: `${elem.branch_name} (${elem.branch_code})`,
                    value: elem.branch_id,
                  })
                }
              } else {
                itemsJoin.push({
                  label: `${elem.branch_name} (${elem.branch_code})`,
                  value: elem.branch_id,
                })
              }
            })
            this.branches = itemsJoin
          } else {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: "Get Branches Failed",
                icon: "AlertTriangleIcon",
                variant: "danger",
              },
            })
          }
        })
        .catch(e => {
          console.log(e)
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Get Branches Failed",
              icon: "AlertTriangleIcon",
              variant: "danger",
            },
          })
        })
    },

    getCurrencies() {
      const userToken = this.$cookies.get("userToken")
const headers = {
  "Content-Type": "application/json",
  Authorization: `Bearer ${userToken}`,
}
      axios
        .get(
          `${process.env.VUE_APP_API_URL}${process.env.VUE_APP_API_GET_CURRENCIES}`,
          { headers },
        )
        .then(response => {
          if (response.data.success === true) {
            const itemsJoin = []
            response.data.data.map(elem => {
              itemsJoin.push({
                label: `${elem.currency_full_name} (${elem.currency_symbol})`,
                value: elem.currency_id,
              })
            })
            this.currencies = itemsJoin
          } else {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: "Get Currencies Failed",
                icon: "AlertTriangleIcon",
                variant: "danger",
              },
            })
          }
        })
        .catch(e => {
          console.log(e)
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Get Branches Failed",
              icon: "AlertTriangleIcon",
              variant: "danger",
            },
          })
        })
    },
      getDetail() {
        const userToken = this.$cookies.get("userToken")
        const headers = {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userToken}`,
        }
        const item = JSON.parse(localStorage.getItem("podetail"))
        this.chosenContact = {
                label: `${item.contact.contact_name} (${item.contact.contact_city})`,
                value: item.contact.contact_id,
              }
              this.purchaseOrderId = item.purchase_order_id
        this.shipmentBy = item.purchase_order_shipment_by
        this.chosenPaymentType = item.purchase_order_payment_type
        this.deliveryAddress = item.purchase_order_delivery_address
        this.number = item.purchase_order_number
        this.purchaseOrderByPurchasingIsConfirmed = item.purchase_order_is_received_by_purchasing !== null ? true : false
        this.purchaseOrderByWarehouseIsConfirmed = item.purchase_order_is_received_by_warehouse !== null ? true : false
        this.deliverySchedule = item.purchase_order_delivery_schedule
        console.log("Tanggal, ",item.purchase_order_delivery_schedule)
        this.purchaseOrderDiscountPercentage = item.purchase_order_discount_percentage
        this.purchaseOrderLink = item.purchase_order_link
        this.purchaseOrderTransportFee = item.purchase_order_transport_fee
        this.chosenBranch = {
                  label: `${item.branch.branch_name} (${item.branch.branch_code})`,
                  value: item.branch.branch_id,
                }
        this.termOfPayment = item.purchase_order_term_of_payment
        this.chosenPpn = item.purchase_order_is_with_tax === true ? "Yes" : "No"
        this.purchaseOrderIsConfirmed = item.purchase_order_is_confirmed
        const id = item.purchase_order_id
        const obj1 = {
          title: "Created",
          content: `Purchase Order Has been Created By ${
            item.user.user_fullname
          } at ${this.dateSimple(
            item.purchase_order_created_time,
            "YYYY-MM-DD HH:mm:ss",
          )}`,
          stepCssClass: "has-step-green",
          boxCssClass: "has-color-red",
        }
        const obj2 = {
          title: "Laboratory Manager",
          content:
            item.purchase_order_is_confirmed === true
              ? `Purchase Order Has been Accepted by ${
                  item.lab_manager.user_fullname
                } at ${this.dateSimple(
                  item.purchase_order_is_confirmed_date,
                  "YYYY-MM-DD HH:mm:ss",
                )}`
              : `Purchase Order Has not been Accepted By Laboratory Manager`,
          stepCssClass:
            item.purchase_order_is_confirmed === true
              ? "has-step-green"
              : "has-step-red",
        }
        const obj3 = {
          title: "Received Purchasing",
          content:
            item.purchase_order_is_received_by_purchasing === true
              ? `Purchase Order Has been Received By Purchasing at ${this.dateSimple(
                  item.purchase_order_is_received_by_purchasing_time,
                  "YYYY-MM-DD HH:mm:ss",
                )}`
              : `Purchase Order Has not been Received by Purchasing Lokal`,
          stepCssClass:
            item.purchase_order_is_received_by_purchasing === true
              ? "has-step-green"
              : "has-step-red",
        }
        const obj4 = {
          title: "Received Warehouse",
          content:
            item.purchase_order_is_received_by_warehouse === true
              ? `Purchase Order Has been Received By Warehouse at ${this.dateSimple(
                  item.purchase_order_is_received_by_warehouse_time,
                  "YYYY-MM-DD HH:mm:ss",
                )}`
              : `Purchase Order Has not been Received by Warehouse`,
          stepCssClass:
            item.purchase_order_is_received_by_warehouse === true
              ? "has-step-green"
              : "has-step-red",
        }
        this.statusItems = []
        this.statusItems.push(obj1)
        this.statusItems.push(obj2)
        this.statusItems.push(obj3)
        this.statusItems.push(obj4)
        axios
          .get(
            `${process.env.VUE_APP_API_URL}${process.env.VUE_APP_API_GET_PURCHASE_ORDER_DETAILS}${id}`,
            { headers },
          )
          .then(response => {
            if (response.data.success === true) {
              this.items = response.data.data
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: "Fetching Data success",
                  icon: "CheckIcon",
                  variant: "success",
                },
              })
            } else {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: "Fetching Data failed",
                  icon: "CheckIcon",
                  variant: "danger",
                },
              })
            }
          })
      },
      print() {
        const userToken = this.$cookies.get("userToken")
        const headers = {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userToken}`,
        }
        const item = JSON.parse(localStorage.getItem("podetail"))
        const id = item.purchase_order_id
        axios
          .get(
            `${process.env.VUE_APP_API_URL}${process.env.VUE_APP_API_DOWNLOAD_PURCHASE_ORDER}${id}`,
            { headers },
          )
          .then(response => {
            if (response.data.success === true) {
              const url = `${process.env.VUE_APP_API_URL}temp/${response.data.data}`
              window.open(url, "_blank")
            } else {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: "Download Data failed",
                  icon: "CheckIcon",
                  variant: "danger",
                },
              })
            }
          })
      },
      getOptionLabel(option) {
        return (option && option.label) || ""
      },
      putItem(val) {
        if (this.chosens.length > 0) {
          let same = false
          this.chosens.map(elem => {
            if (elem.value === val.value) {
              same = true
            }
          })
          if (same === false) {
            let item = null
            this.items.map(elem => {
              if (elem.value === val.value) {
                item = { label: elem.label, value: elem.value, qty: 1 }
              }
            })
            this.chosens.push(item)
          }
        } else {
          let item = null
          this.items.map(elem => {
            if (elem.value === val.value) {
              item = { label: elem.label, value: elem.value, qty: 1 }
            }
          })

          this.chosens.push(item)
        }
      },
      applyItem(val) {
        console.log(val.data)
      },
      getOptionKey(option) {
        return (option && option.value) || ""
      },
      inputSearch(search) {
        console.log(search)
      },
      removeRow(val) {
        this.chosens = this.chosens.filter(obj => obj.value !== val)
      },
      dateFormat(value, format = "MM/DD/YYYY") {
        return moment(String(value)).format(format)
      },
      dateSimple(value, format = "YYYY-MM-DD") {
        let dateRet = ""
        if (value !== null) {
          dateRet = moment(String(value)).format(format)
        } else {
          dateRet = null
        }
        return dateRet
      },
      previous() {
        this.$router.push({ name: "apps-procurement-purchase-order-list" })
      },
      numberFormat(value) {
        return parseFloat(value).toFixed(2)
      },

    getContacts() {
      const userToken = this.$cookies.get("userToken")
const headers = {
  "Content-Type": "application/json",
  Authorization: `Bearer ${userToken}`,
}
      axios
        .get(
          `${process.env.VUE_APP_API_URL}${process.env.VUE_APP_API_GET_CONTACTS}`,
          { headers },
        )
        .then(response => {
          if (response.data.success === true) {
            const itemsJoin = []
            response.data.data.map(elem => {
              itemsJoin.push({
                label: `${elem.contact_name} (${elem.contact_city})`,
                value: elem.contact_id,
              })
            })
            this.contacts = itemsJoin
          } else {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: "Get Contacts Failed",
                icon: "AlertTriangleIcon",
                variant: "danger",
              },
            })
          }
        })
        .catch(e => {
          console.log(e)
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Get Contacts Failed",
              icon: "AlertTriangleIcon",
              variant: "danger",
            },
          })
        })
    },
      capitalizeWords(str) {
        // Split the string into words using spaces as the separator
        const words = str.split(" ")
        // Capitalize the first letter of each word and join them back together
        const capitalizedWords = words.map(word => {
          return word.charAt(0).toUpperCase() + word.slice(1)
        })
        // Join the capitalized words into a single string with spaces
        return capitalizedWords.join(" ")
      },
    submitEditPO() {
      this.submitButtonDisabled = true
      const userToken = this.$cookies.get("userToken")
const headers = {
  "Content-Type": "application/json",
  Authorization: `Bearer ${userToken}`,
}
console.log(this.deliverySchedule)
      if (this.deliverySchedule !== "") {
        const prIds = []
        this.chosens.map(elem => {
          if (!prIds.includes(elem.pr_id)) {
            prIds.push(elem.pr_id)
          }
        })
        const body = {
          purchase_order_id: this.purchaseOrderId,
          purchase_order_suppplier: this.chosenContact.value,
          purchase_order_shipment_by: this.shipmentBy,
          purchase_order_delivery_address: this.deliveryAddress,
          purchase_order_delivery_schedule: this.deliverySchedule,
          purchase_order_term_of_payment: this.termOfPayment,
          purchase_order_billing_address: this.chosenBranch.value,
          purchase_order_payment_type: this.chosenPaymentType,
          purchase_order_link: this.purchaseOrderLink,
          purchase_order_is_with_tax: this.chosenPpn,
          purchase_order_transport_fee: this.purchaseOrderTransportFee,
          purchase_order_discount_percentage: this
        .purchaseOrderDiscountPercentage,
        purchase_order_items: JSON.stringify(this.items),
          time: moment(),
        }
        axios
          .post(
            `${process.env.VUE_APP_API_URL}${process.env.VUE_APP_API_EDIT_PURCHASE_ORDER}`,
            body,
            { headers },
          )
          .then(response => {
      this.submitButtonDisabled = false
            if (response.data.success === true) {
              this.submitButtonDisabled = false
              this.chosens = []
              this.chosenContact = ""
              this.shipmentBy = ""
              this.deliveryAddress = ""
              this.deliverySchedule = ""
              this.termOfPayment = ""
              this.chosenBranch = null
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: "Create Purchase Order Success",
                  icon: "CheckIcon",
                  variant: "success",
                },
              })
              this.$router.replace({
                name: "apps-procurement-purchase-order-list",
              })
            } else {
              this.submitButtonDisabled = false
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: "Purchase Order Failed",
                  icon: "AlertTriangleIcon",
                  variant: "danger",
                },
              })
            }
          })
          .catch(() => {
              this.submitButtonDisabled = false
            this.$toast({
              component: ToastificationContent,
              props: {
                title: "Create Purchase Order Failed",
                icon: "AlertTriangleIcon",
                variant: "danger",
              },
            })
          })
        } else {
              this.submitButtonDisabled = false
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Please fill price fields with numbers only",
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        })
        }
    },
    },
  }
  </script>
